@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@200;300;400;600&display=swap');


html{
  scroll-behavior: smooth;

}

body {
  margin: 0;
  font-family: 'Heebo', sans-serif;
 /* font-family: 'Segoe UI'; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(116, 119, 255, 0.15);
}


